<template>
  <div
    v-if="
      currentSiteSettings.facebook && currentSiteSettings.facebook.integrate
    "
    :class="[
      'z-[1000] fixed right-6 bottom-4', // Adjust as needed
      currentSiteSettings.intercom.integrate && currentSiteSettings.whatsapp
        ? 'double-has-bottom'
        : currentSiteSettings.intercom.integrate || currentSiteSettings.whatsapp
        ? 'has-messenger-bottom'
        : 'has-bottom',
    ]"
  >
    <a
      :href="`http://m.me/${currentSiteSettings.facebook.page_id}`"
      target="_blank"
    >
      <img
        :src="messenger"
        alt="Messenger Meme"
        class="w-[40px] h-auto rounded-[8px]"
      />
    </a>
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
import messenger from "@/assets/images/messenger.png";
const store = useMainStore();
const currentSiteSettings = store.currentSiteSettings;
</script>
<style>
.has-messenger-bottom {
  @apply bottom-20;
}
.double-has-bottom {
  @apply bottom-[170px];
}
</style>
